<template>
  <div
    id="banner_content"
    class="d-flex flex-column justify-center align-center secondary"
  >
    <v-spacer></v-spacer>
    <div id="app_bar_title" class="white--text">RPD IA FORMS</div>
    <v-spacer></v-spacer>
    <!-- <v-app-bar app dense color="secondary" id="ourappbar">
      <v-toolbar-title class="white--text">RPD IA FORMS</v-toolbar-title>
    </v-app-bar> -->
  </div>
</template>

<style lang="scss" scoped>
@import "../scss/site.scss";
#banner_content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
}

#app_bar_title {
  font-size: 1.2rem;
  font-weight: 600;
}

@media (min-width: 1025px) {
}
</style>
#
<script>
/* eslint-disable */
import { bus_common } from "../mixins/bus_common";
export default {
  name: "banner",
  mixins: [bus_common],
  data() {
    return {};
  },
  methods: {
    message1() {
      alert("This button does nothing yet.");
    },
    init() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
